const COLOR_RED = {
  LOWLIGHT: '#CC0049',
  BASE: '#FF0057',
  HIGHLIGHT: '#FFD4E6',
  TINT: '#FFE8F0',
} as const;

const COLOR_ORANGE = {
  LOWLIGHT: '#DD3C00',
  BASE: '#FF5800',
  HIGHLIGHT: '#FFE1CB',
  TINT: '#FFF0E3',
} as const;

const COLOR_YELLOW = {
  LOWLIGHT: '#FFB700',
  BASE: '#FFCF00',
  HIGHLIGHT: '#FFFADD',
  TINT: '#FFFCEC',
} as const;

const COLOR_GREEN = {
  LOWLIGHT: '#008B4A',
  BASE: '#00D47D',
  HIGHLIGHT: '#ACF8E2',
  TINT: '#DCFFF3',
} as const;

const COLOR_BLUE = {
  LOWLIGHT: '#006EB2',
  BASE: '#00BFFF',
  HIGHLIGHT: '#B0F1FC',
  TINT: '#D9F8FF',
} as const;

const COLOR_GRAY = {
  LOWLIGHT: '#001B38',
  BASE: '#808080',
  HIGHLIGHT: '#F3F2F0',
  TINT: '#F5F5F5',
} as const;

const COLOR_BLACK = COLOR_GRAY.LOWLIGHT;
const COLOR_WHITE = '#FFFFFF';

const GRADIENT_DARK = `linear-gradient(209deg, ${COLOR_BLACK} 23.25%, ${COLOR_BLUE.LOWLIGHT} 161.08%)`;

export const COLORS = {
  /* Colors */
  RED: COLOR_RED,
  ORANGE: COLOR_ORANGE,
  YELLOW: COLOR_YELLOW,
  GREEN: COLOR_GREEN,
  BLUE: COLOR_BLUE,

  /* Grays */
  BLACK: COLOR_BLACK,
  GRAY: COLOR_GRAY,
  WHITE: COLOR_WHITE,
} as const;

export const GRADIENTS = {
  DARK: GRADIENT_DARK,
} as const;
