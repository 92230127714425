import SecondaryDefaultButton from '@/components/buttons/secondary/DefaultButton';
import { COLORS } from '@/styles/color';
import styled from 'styled-components';

const GLOW_PADDING = 16;

const SecondaryOuterGlowButton = styled(SecondaryDefaultButton)`
  --button-animation-speed: 0.35s;

  position: relative;

  background-color: var(--button-color, ${COLORS.WHITE});

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + ${GLOW_PADDING * 2}px);
    height: calc(100% + ${GLOW_PADDING * 2}px);
    top: -${GLOW_PADDING}px;
    left: -${GLOW_PADDING}px;
    background: radial-gradient(50% 50% at 50% 50%, var(--button-glow-color, ${COLORS.RED.BASE}) 0%, transparent 100%);
    opacity: 0;
    z-index: -1;

    transition: opacity var(--button-animation-speed, 0.15s) ease-in-out;
  }

  &:hover {
    background-color: var(--button-color-hover, ${COLORS.WHITE});
    color: var(--button-text-color-hover, ${COLORS.BLACK});

    &::before {
      opacity: 0.7;
    }
  }
`;

export default SecondaryOuterGlowButton;
