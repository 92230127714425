import PrimaryInnerGlowButton from '@/components/buttons/primary';
import Link from '@/components/Link';
import { FormStepFields } from '@/components/modals/DemoModal/FormStep';
import Shared from '@/components/modals/shared';
import Step from '@/components/modals/shared/Step';
import { HubspotFieldNames } from '@/constants/hubspot';
import { PRODUCT_TOUR_PAGE } from '@/constants/routes';
import { ChiliPiperSession, ChiliPiperTargetID } from '@/lib/chili-piper';
import React, { FC, useEffect, useRef, useState } from 'react';
import Styled from './components';

interface ChiliPiperStepProps {
  formFields: FormStepFields;
  isPayingCustomer: boolean;
  showGenericConfirmation: () => void;
  onDemoClick: () => void;
}

const ChiliPiperStep: FC<ChiliPiperStepProps> = ({
  formFields,
  isPayingCustomer,
  showGenericConfirmation,
  onDemoClick,
}) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showFindAnotherTime, setShowFindAnotherTime] = useState(false);
  const [meetingType, setMeetingType] = useState<'scheduled' | 'phone' | null>(null);

  const chiliPiperSession = useRef<ChiliPiperSession | null>(null);
  const onCloseOrError = useRef<() => void>(showGenericConfirmation);

  const showChiliPiper = !(showConfirmation && meetingType === 'phone');

  const reset = () => {
    setShowConfirmation(false);
    setMeetingType(null);
    setShowFindAnotherTime(false);
  };

  useEffect(() => {
    const session = new ChiliPiperSession(
      {
        email: formFields[HubspotFieldNames.Email],
        first_name: formFields[HubspotFieldNames.FirstName],
        last_name: formFields[HubspotFieldNames.LastName],
        company_name: formFields[HubspotFieldNames.CompanyName],
        company_size: formFields[HubspotFieldNames.CompanySize],
        industry: formFields[HubspotFieldNames.Industry],
        phone: formFields[HubspotFieldNames.Phone],
      },
      (eventName, eventArgs) => {
        switch (eventName) {
          case 'phone-selected':
            setShowFindAnotherTime(false);
            setMeetingType('phone');
            break;
          case 'availability-loaded':
            setMeetingType('scheduled');
            setShowFindAnotherTime(true);
            break;
          case 'timeslot-selected':
            setShowFindAnotherTime(false);
            break;
          case 'booked':
          case 'booking-confirmed':
            setShowConfirmation(true);
            break;
          case 'phoneClose':
            setShowConfirmation(true);

            onCloseOrError.current = () => {
              // Override and do nothing
            };

            break;
          case 'onClose':
          case 'onError':
            onCloseOrError.current();
            break;
        }
      },
    );

    chiliPiperSession.current = session;

    if (isPayingCustomer) {
      session.markExistingCustomer();
      showGenericConfirmation();
    } else {
      reset();
      session.loadFrame();
    }

    return () => {
      if (chiliPiperSession.current) {
        chiliPiperSession.current.destroy();
        chiliPiperSession.current = null;
      }
    };
  }, [formFields, isPayingCustomer]);

  return (
    <>
      {showConfirmation && meetingType === 'phone' ? (
        <Step
          heading="It was great speaking with you!"
          subheading="Hopefully we were able to connect. If not, please schedule some dedicated time with our sales team."
        >
          <Shared.Footer>
            <span>{/* Leave empty */}</span>
            <div>
              <PrimaryInnerGlowButton
                onClick={() => {
                  reset();

                  if (chiliPiperSession.current) {
                    chiliPiperSession.current.destroy();
                    chiliPiperSession.current.loadFrame(true);
                  }
                }}
              >
                Book a meeting
              </PrimaryInnerGlowButton>
            </div>
          </Shared.Footer>
        </Step>
      ) : null}

      <div
        style={{
          display: showChiliPiper ? 'block' : 'none',
        }}
      >
        <ChiliPiperWrapper />
      </div>

      {showChiliPiper && showConfirmation && meetingType === 'scheduled' ? (
        <Styled.Footer>
          <p>In the meantime, explore Front with our product tour.</p>

          <Link href={PRODUCT_TOUR_PAGE}>
            <PrimaryInnerGlowButton as="a" onClick={onDemoClick}>
              Take a tour
            </PrimaryInnerGlowButton>
          </Link>
        </Styled.Footer>
      ) : null}

      {showChiliPiper && showFindAnotherTime ? (
        <Styled.Footer>
          <p>
            <strong>Don’t see a time that works for you?</strong> Have a Front specialist reach out to find a time that
            fits your schedule
          </p>

          <PrimaryInnerGlowButton
            type="button"
            onClick={() => {
              if (chiliPiperSession.current) {
                chiliPiperSession.current.requestFollowUp();
              }

              showGenericConfirmation();
            }}
          >
            Request a different time
          </PrimaryInnerGlowButton>
        </Styled.Footer>
      ) : null}
    </>
  );
};

export default ChiliPiperStep;

const ChiliPiperWrapper: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  return <Styled.ChiliPiperTarget ref={ref} id={ChiliPiperTargetID} />;
};
