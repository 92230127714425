import GlobalAnnouncementBanner from '@/components/banners/GlobalAnnouncementBanner';
import DemoModal from '@/components/modals/DemoModal';
import GetStartedModal from '@/components/modals/GetStartedModal/Modal';
import TrialModal from '@/components/modals/TrialModal';
import PrimaryFooter from '@/components/navigation/PrimaryFooter';
import PrimaryNavigation, { PRIMARY_NAV_HEIGHT } from '@/components/navigation/PrimaryNavigation';
import { GlobalContext } from '@/context';
import { remCalc } from '@/styles/typography/utils';
import React, { FC, PropsWithChildren, useContext } from 'react';
import styled from 'styled-components';

const TemplateWrapper = styled.section`
  position: relative;
`;

const MainWrapper = styled.main`
  position: relative;
  margin-top: ${remCalc(-PRIMARY_NAV_HEIGHT)};
`;

interface MasterTemplateProps extends PropsWithChildren {
  showAnnouncement?: boolean;
  navigation?: React.ReactNode;
  footer?: React.ReactNode;
  globalIncludes?: React.ReactNode;
}

const MasterTemplate: FC<MasterTemplateProps> = ({ children, ...props }) => {
  const { showAnnouncement, navigation, footer, globalIncludes } = props;
  const { showGetStartedModal, showTrialModal, showDemoModal } = useContext(GlobalContext);

  return (
    <TemplateWrapper>
      {showAnnouncement && <GlobalAnnouncementBanner />}

      {/* Navigation */}
      {navigation ?? <PrimaryNavigation />}

      {/* Page content */}
      <MainWrapper>{children}</MainWrapper>

      {/* Footer */}
      {footer ?? <PrimaryFooter />}

      {/* Modals */}
      {showGetStartedModal && <GetStartedModal />}
      <TrialModal />
      {showDemoModal && <DemoModal />}

      {/* Global Includes */}
      {globalIncludes ?? null}
    </TemplateWrapper>
  );
};

MasterTemplate.defaultProps = {
  showAnnouncement: true,
};

export default MasterTemplate;
