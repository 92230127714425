import PrimaryInnerGlowButton from '@/components/buttons/primary';
import { InputFieldLabel } from '@/components/forms/InputField';
import { SelectFieldLabel } from '@/components/forms/SelectField';
import ModalComponent, { StyledModalContainer } from '@/components/modals/Modal';
import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { marginX, mediaBreakpointUp, paddingY } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import styled from 'styled-components';

const BLACK = '#19191A';
const MEDIUM_GRAY = '#77787A';

const Modal = styled(ModalComponent)`
  --modal-max-width: 1084px;
  --modal-padding: 0;

  ${StyledModalContainer} {
    overflow: hidden;
  }
`;

const ModalWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  ${mediaBreakpointUp('tablet')} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
`;

const FormColumn = styled.div`
  --form-column-padding: ${remCalc(24)};

  position: relative;
  padding: var(--form-column-padding);
  display: flex;
  flex-direction: column;

  h2,
  h3 {
    + p {
      ${customTextSize(14, 18)}
      margin-top: ${remCalc(12)};
      font-weight: ${FONT_WEIGHTS.MEDIUM};
    }
  }

  h2 {
    ${customTextSize(24, 29)};
    margin-top: ${remCalc(24)};
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  }

  h3 {
    ${customTextSize(20, 24)};
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  }

  h4 {
    ${customTextSize(18, 22)};
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
    padding-top: 2px;
    margin-top: 24px;
    margin-bottom: 0.25rem;
  }

  p + p {
    margin-top: 1rem;
  }

  ul {
    margin-top: 1.5rem;

    a {
      display: inline-flex;
      border-bottom: 2px solid currentColor;
      ${customTextSize(16, 20)};
      font-weight: ${FONT_WEIGHTS.MEDIUM};
      margin-bottom: 0.5rem;
    }
  }

  form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${InputFieldLabel}, ${SelectFieldLabel} {
      ${customTextSize(13, 13)};
      font-weight: ${FONT_WEIGHTS.SEMIBOLD};
      color: ${COLORS.BLACK};
    }

    fieldset {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      column-gap: 1rem;

      > * {
        flex: 1 1 0;
      }

      ${mediaBreakpointUp('tablet')} {
        flex-direction: row;
      }
    }
  }

  ${mediaBreakpointUp('tablet')} {
    --form-column-padding: ${remCalc(32)};
  }

  ${mediaBreakpointUp('desktop')} {
    --form-column-padding: ${remCalc(48)};
  }
`;

const Footer = styled.div`
  margin: auto -24px -24px -24px;
  padding: 56px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${customTextSize(13, 16)};
  color: ${MEDIUM_GRAY};

  > :last-child {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
  }

  ${PrimaryInnerGlowButton} {
    min-width: 0;
  }

  ${mediaBreakpointUp('tablet')} {
    ${marginX(-32)};
  }

  ${mediaBreakpointUp('desktop')} {
    ${marginX(-48)};
  }
`;

const SocialProofColumn = styled.div`
  position: relative;
  background: ${hexToRGBA(COLORS.BLUE.TINT, 0.5)};
  ${paddingY(72)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mediaBreakpointUp('tablet')} {
    ${paddingY(48)};
  }
`;

export default {
  BLACK,
  MEDIUM_GRAY,
  Modal,
  ModalWrapper,
  FormColumn,
  Footer,
  SocialProofColumn,
};
