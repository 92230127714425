import PrimaryDefaultButton from '@/components/buttons/primary/DefaultButton';
import { COLORS } from '@/styles/color';
import styled from 'styled-components';

const PrimaryInnerGlowButton = styled(PrimaryDefaultButton)`
  --button-animation-speed: 0.35s;

  background-image: radial-gradient(circle closest-side, transparent 0%, var(--button-color, ${COLORS.BLACK}) 140%);
  background-position: calc(var(--x) + 50%) 5%;
  background-repeat: no-repeat;
  background-size: 200% 400%;
  background-origin: border-box;

  border-color: transparent;
`;

PrimaryInnerGlowButton.defaultProps = {
  onMouseMove: (e) => {
    const button = e.target as HTMLElement;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // prettier-ignore
    button.style.setProperty('--x', `${50 - ((x / rect.width) * 100)}%`);
  },
};

export default PrimaryInnerGlowButton;
