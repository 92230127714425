import PrimaryDefaultButton from '@/components/buttons/primary/DefaultButton';
import { COLORS } from '@/styles/color';
import styled from 'styled-components';

const SecondaryDefaultButton = styled(PrimaryDefaultButton)`
  border-color: var(--button-color, ${COLORS.BLACK});
  background-color: transparent;
  color: var(--button-text-color, ${COLORS.BLACK});

  &:hover {
    border-color: var(--button-color-hover, ${COLORS.BLACK});
    background-color: var(--button-color-hover, ${COLORS.BLACK});
    color: var(--button-text-color-hover, ${COLORS.WHITE});
  }
`;

export default SecondaryDefaultButton;
