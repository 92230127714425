import { ReactComponent as FacebookIcon } from '@/assets/images/svg/logos/social/facebook.svg';
import { ReactComponent as InstagramIcon } from '@/assets/images/svg/logos/social/instagram.svg';
import { ReactComponent as LinkedInIcon } from '@/assets/images/svg/logos/social/linkedin.svg';
import { ReactComponent as TwitterIcon } from '@/assets/images/svg/logos/social/twitter.svg';

export const SOCIAL_MEDIA_ACCOUNTS = [
  {
    handle: 'twitter',
    icon: <TwitterIcon />,
    shareUrl: (href: string, text: string) =>
      `https://twitter.com/intent/tweet?url=${href}&via=FrontHQ&text=${encodeURIComponent(text)}`,
  },
  {
    handle: 'facebook',
    icon: <FacebookIcon />,
    shareUrl: (href: string, text: string) =>
      `https://www.facebook.com/sharer/sharer.php?u=${href}&quote=${encodeURIComponent(text)}`,
  },
  {
    handle: 'linkedin',
    icon: <LinkedInIcon />,
    shareUrl: (href: string, text?: string) => `https://www.linkedin.com/sharing/share-offsite/?url=${href}`,
  },
  {
    handle: 'instagram',
    icon: <InstagramIcon />,
    shareUrl: null,
  },
] as const;
