import { ReactComponent as WhiteCloseIcon } from '@/assets/images/svg/glyphs/close-white.svg';
import { ReactComponent as CloseIcon } from '@/assets/images/svg/glyphs/close.svg';
import gtm from '@/lib/gtm';
import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { LockBodyScroll } from '@/styles/layout/body';
import { mediaBreakpointUp } from '@/styles/layout/utils';
import { remCalc } from '@/styles/typography/utils';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';

export const MODAL_BKGD_COLOR = '#19191A';

const StyledModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: var(--modal-backdrop-color, ${hexToRGBA(MODAL_BKGD_COLOR, 0.9)});
  backdrop-filter: blur(3px);
  z-index: 1000;
`;

export const StyledModalScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  padding: 18px;
`;

export const StyledModalContainer = styled.div`
  position: relative;
  background: var(--modal-background-color, ${COLORS.WHITE});
  border-radius: 10px;
  padding: var(--modal-padding, ${remCalc(40)});
  margin: auto;
  width: var(--modal-width, 100%);
  max-width: var(--modal-max-width, auto);
  height: var(--modal-height, auto);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.3);

  ${mediaBreakpointUp('desktop')} {
    display: flex;
    flex-direction: column;
  }
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: var(--modal-button-top, ${remCalc(8)});
  right: var(--modal-button-right, ${remCalc(8)});

  appearance: none;
  border: none;
  background: none;
  box-sizing: content-box;

  width: 16px;
  height: 16px;

  padding: 8px;
  margin: 0;

  cursor: pointer;

  isolation: isolate;
  z-index: 999999;
`;

interface ModalProps extends PropsWithClassName, PropsWithChildren {
  name: string;
  id?: string;
  onClose?: () => void;
  preventClose?: boolean;
  whiteCloseButton?: boolean;
  sendEvents?: boolean;
}

export const Modal: FC<ModalProps> = ({
  children,
  onClose,
  name,
  className,
  id,
  preventClose,
  whiteCloseButton,
  sendEvents = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  /* Run this only once */
  useEffect(() => {
    if (sendEvents) {
      gtm.track('modal_opened', {
        modal_label: name,
      });
    }
  }, []);

  const onModalClose = () => {
    if (sendEvents) {
      gtm.track('modal_closed', {
        modal_label: name,
      });
    }

    if (onClose) onClose();
  };

  return (
    <>
      <StyledModalBackdrop ref={ref} className={className} id={id}>
        <StyledModalScrollWrapper data-modal-part="scroll-wrapper">
          <StyledModalContainer>
            {!preventClose && (
              <StyledCloseButton onClick={onModalClose}>
                {whiteCloseButton ? <WhiteCloseIcon /> : <CloseIcon />}
              </StyledCloseButton>
            )}

            {children}
          </StyledModalContainer>
        </StyledModalScrollWrapper>
      </StyledModalBackdrop>
      <LockBodyScroll />
    </>
  );
};

export default Modal;
