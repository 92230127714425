import PrimaryDefaultButton from '@/components/buttons/primary/DefaultButton';
import { COLORS } from '@/styles/color';
import styled from 'styled-components';

const SecondaryInnerGlowButton = styled(PrimaryDefaultButton)`
  --button-animation-speed: 0.35s;

  border-color: var(--button-color, ${COLORS.BLACK});
  background-color: transparent;
  color: var(--button-text-color, ${COLORS.BLACK});

  background-image: radial-gradient(circle closest-side, transparent 0%, var(--button-color, ${COLORS.WHITE}) 140%);
  background-position: calc(var(--x) + 50%) 5%;
  background-origin: center;
  background-repeat: no-repeat;
  background-size: 200% 400%;
  background-origin: border-box;

  &:hover {
    color: var(--button-text-color-hover, ${COLORS.BLACK});
  }
`;

SecondaryInnerGlowButton.defaultProps = {
  onMouseMove: (e) => {
    const button = e.target as HTMLElement;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // prettier-ignore
    button.style.setProperty('--x', `${(50 - (x / rect.width)) * 100}%`);
  },
};

export default SecondaryInnerGlowButton;
