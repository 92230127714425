import { ReactComponent as FrontLogoHorizontal } from '@/assets/images/svg/logos/front/front-logo-horizontal.svg';
import Image from '@/components/Image';
import Shared from '@/components/modals/shared';
import React, { FC, useState } from 'react';
import Styled from './components';
import InitialForm, { InitialFormValues } from './InitialForm';
import SecondaryForm from './SecondaryForm';
import { ReactComponent as Sparkle1 } from './sparkle-1.svg';
import { ReactComponent as Sparkle2 } from './sparkle-2.svg';

const TOTAL_STEPS = 2;

const QUOTES = [
  {
    quote:
      'My team literally could not do what they do without Front. With one tool to manage hundreds of partners and thousands of accounts, Front gives our team the ability to work efficiently and deliver a great experience.',
    name: 'Trish Bingham',
    title: 'Vice President, Client Services, Boostability',
    image: '/assets/authors/trish-bingham.png',
  },
  {
    quote:
      'Using Front has allowed us to consistently keep fantastic support in an ever-changing company, across channels, and across time zones.',
    name: 'Jarratt Isted',
    title: 'Co-founder, HelpDocs',
    image: '/assets/authors/jarratt-isted.png',
  },
];

interface SignupModalProps {
  preventClose?: boolean;
  onClose?: () => void;
  step1Heading?: string;
  step1Subheading?: string;
  step2Heading?: string;
  step2Subheading?: string;
}

export interface SignupModalStep {
  heading?: string;
  subheading?: string;
}

const SignupModal: FC<SignupModalProps> = ({
  preventClose = false,
  onClose,
  step1Heading = 'Start your free 7-day trial',
  step1Subheading = 'You’re one step closer to delivering exceptional service at scale.',
  step2Heading = 'Almost there!',
  step2Subheading = 'Help us tailor the getting started experience to your needs.',
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [formValues, setFormValues] = useState<InitialFormValues | undefined>();

  return (
    <Styled.Modal name="Free Trial Modal" preventClose={preventClose} sendEvents={!preventClose} onClose={onClose}>
      <Shared.ModalWrapper>
        <Shared.FormColumn>
          <FrontLogoHorizontal title="Front logo" width={75} />

          {currentStep === 1 && (
            <InitialForm
              heading={step1Heading}
              subheading={step1Subheading}
              onFormSuccess={(initialFormValues) => {
                setFormValues(initialFormValues);
                setCurrentStep(2);
              }}
            />
          )}

          {currentStep === 2 && (
            <SecondaryForm
              heading={step2Heading}
              subheading={step2Subheading}
              suggestDemo={!preventClose}
              initialFormValues={formValues!}
            />
          )}
        </Shared.FormColumn>

        <Shared.SocialProofColumn>
          <Styled.QuoteWrapper>
            <Sparkle1 width={34} />

            <Styled.Quotation>{QUOTES[currentStep - 1].quote}</Styled.Quotation>

            <Styled.QuoteeWrapper>
              <Styled.QuoteeAvatar>
                <Image src={QUOTES[currentStep - 1].image} width="50" height="50" alt="" />
              </Styled.QuoteeAvatar>

              <div>
                <Styled.QuoteeName>{QUOTES[currentStep - 1].name}</Styled.QuoteeName>
                <Styled.QuoteeTitle>{QUOTES[currentStep - 1].title}</Styled.QuoteeTitle>
              </div>
            </Styled.QuoteeWrapper>

            <Sparkle2 width={36} />
          </Styled.QuoteWrapper>
        </Shared.SocialProofColumn>
      </Shared.ModalWrapper>
    </Styled.Modal>
  );
};

export default SignupModal;
