import { Container } from '@/components/layout/Container';
import { Z_INDEX } from '@/styles/layout';
import { BREAKPOINT_PADDING_VAR, mediaBreakpointUp, paddingX, paddingY } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import styled from 'styled-components';

const Banner = styled.div<{ bannerColor: string }>`
  position: relative;
  background-color: ${(props) => props.bannerColor};
  ${customTextSize(12, 18)}
  z-index: ${Z_INDEX.ANNOUNCEMENT_BANNER};

  a {
    font-weight: ${FONT_WEIGHTS.BOLD};
    text-decoration: underline;
    white-space: nowrap;
  }

  ${mediaBreakpointUp('tablet')} {
    ${customTextSize(16, 26)}
    text-align: center;
  }
`;

const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: row;

  p {
    display: inline;
  }
`;

const ContentWrapper = styled.div`
  ${paddingY(16)}
  padding-right: calc(${BREAKPOINT_PADDING_VAR} / 2);
  flex: 1 1 0;

  ${mediaBreakpointUp('desktopWide')} {
    ${paddingX(42, 6)}
  }
`;

const DismissButton = styled.button`
  width: ${BREAKPOINT_PADDING_VAR};
  position: absolute;
  right: 0;
  height: 100%;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaBreakpointUp('desktopWide')} {
    position: relative;
    width: ${remCalc(36)};
    height: auto;
  }
`;

const globalAnnouncementBannerComponents = {
  Banner,
  Container: CustomContainer,
  ContentWrapper,
  DismissButton,
};

export default globalAnnouncementBannerComponents;
