import { COLORS } from '@/styles/color';
import styled from 'styled-components';

const ANIMATION_SPEED = '0.15s';

interface ButtonProps {
  fullWidth?: boolean;
}

const PrimaryDefaultButton = styled.button<ButtonProps>`
  /* Remove all default browser appearance */
  appearance: none;

  background-color: var(--button-color, ${COLORS.BLACK});
  color: var(--button-text-color, ${COLORS.WHITE});

  border: 2px solid var(--button-border-color, transparent);
  border-radius: 9999px;

  margin: 0;
  padding: 13px 24px;

  font-size: 16px;
  text-align: center;

  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  min-width: 132px;

  cursor: pointer;
  display: inline-block;

  transition: background-color var(--button-animation-speed, ${ANIMATION_SPEED}) ease-in-out,
    color var(--button-animation-speed, ${ANIMATION_SPEED}) ease-in-out,
    border-color var(--button-animation-speed, ${ANIMATION_SPEED}) ease-in-out,
    box-shadow var(--button-animation-speed, ${ANIMATION_SPEED}) ease-in-out;

  &:hover {
    background-color: var(--button-color-hover, ${COLORS.RED.LOWLIGHT});
    color: var(--button-text-color-hover, ${COLORS.WHITE});
    border-color: var(--button-border-color-hover, transparent);
  }

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;

export default PrimaryDefaultButton;
