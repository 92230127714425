import { ReactComponent as PrivacyChoicesGlyph } from '@/assets/images/svg/glyphs/privacy-choices.svg';
import { Container } from '@/components/layout/Container';
import Link from '@/components/Link';
import { SOCIAL_MEDIA_ACCOUNTS } from '@/constants/social';
import { FRONT_LEGAL_NAME } from '@/constants/strings';
import { GlobalContext } from '@/context';
import { OneTrustContext } from '@/context/onetrust';
import { Maybe } from '@/graphql';
import gtm from '@/lib/gtm';
import { maybeNotSet } from '@/lib/typescript-helpers';
import { COLORS } from '@/styles/color';
import { mediaBreakpointDown, mediaBreakpointUp, paddingX, paddingY } from '@/styles/layout/utils';
import { FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { remCalc } from '@/styles/typography/utils';
import { capitalize } from 'lodash';
import { FC, useContext } from 'react';
import styled from 'styled-components';

const ANALYTICS_CATEGORY = 'Primary Footer';
const SHARE_ICONS = SOCIAL_MEDIA_ACCOUNTS.filter((account) =>
  ['linkedin', 'twitter', 'facebook', 'instagram'].includes(account.handle),
);

// #region - Styled Components

const Footer = styled(Container)`
  padding-top: ${remCalc(40)};
`;

const CategoryWrapper = styled.div`
  ${mediaBreakpointDown('tablet')} {
    column-count: 2;
    column-gap: ${remCalc(30)};
  }

  ${mediaBreakpointUp('desktop')} {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    grid-column-gap: 2rem;
  }
`;

const Category = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: ${remCalc(16)};
`;

const CategoryEyebrow = styled.p`
  ${TEXT_SIZES[12]};

  color: ${COLORS.RED.LOWLIGHT};
  text-transform: uppercase;
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  letter-spacing: 0.08em;
  margin-bottom: ${remCalc(38)};
`;

const CategoryListItem = styled.li<{ bold?: Maybe<boolean> }>`
  ${TEXT_SIZES[13]};
  margin-bottom: ${remCalc(20)};
  font-weight: ${(props) => (props.bold ? FONT_WEIGHTS.BOLD : FONT_WEIGHTS.NORMAL)};

  a {
    display: inline-block;
    ${paddingY(8)};
  }

  ${mediaBreakpointUp('desktop')} {
    ${TEXT_SIZES[16]};
  }
`;

const SplitSections = styled.div`
  ${paddingY(40)};

  display: flex;
  flex-direction: column;
  border-top: 1px solid ${COLORS.BLACK};

  ${mediaBreakpointUp('desktop')} {
    padding-top: ${remCalc(40 - 8)};

    grid-column-start: 1;
    grid-column-end: span 3;

    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
`;

const SocialMediaLinks = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: ${remCalc(40)};

  ${mediaBreakpointUp('desktop')} {
    flex-shrink: 0;
    margin-bottom: 0;
  }
`;

const SocialMediaLink = styled.li`
  margin-right: ${remCalc(24)};

  &:last-of-type {
    margin-right: 0;
  }

  ${mediaBreakpointUp('desktop')} {
    width: ${remCalc(40)};
  }
`;

const LegalLinks = styled.ul`
  display: flex;
  flex-direction: column;

  ${mediaBreakpointUp('desktop')} {
    ${paddingX(30)};

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const LegalLink = styled.li`
  ${TEXT_SIZES[13]};
  ${paddingY(8)};

  a {
    ${paddingY(8)};
    cursor: pointer;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.35rem;

    &:hover {
      text-decoration: underline;
    }
  }

  ${mediaBreakpointUp('desktop')} {
    ${TEXT_SIZES[16]};
    ${paddingX(16)};

    &:last-of-type {
      margin-right: 0;
    }

    a {
      ${paddingY(0)};
    }
  }
`;

const LegalFooterCopy = styled.p`
  ${TEXT_SIZES[13]};

  flex-shrink: 0;
  margin-top: ${remCalc(40)};

  ${mediaBreakpointUp('desktop')} {
    ${TEXT_SIZES[16]};

    display: inline-block;
    margin-top: 0;
  }
`;

// #endregion

const PrimaryFooter: FC = () => {
  const {
    metadata,
    navigation: { primaryFooter, legalFooter },
  } = useContext(GlobalContext);

  const { toggleInfoDisplay, geo } = useContext(OneTrustContext);

  // Create the social media links
  const socialMediaLinks = SHARE_ICONS.map(({ handle, icon }) => {
    const socialAccount = metadata?.sameAsLinks[handle];

    if (!socialAccount) return null;

    return (
      <SocialMediaLink key={socialAccount.handle}>
        <a
          href={socialAccount.url}
          target="_blank"
          title={`Front's ${capitalize(socialAccount.handle)} account`}
          onClick={() => {
            gtm.track('click', {
              element_type: 'link',
              location: ANALYTICS_CATEGORY,
              element_label: `Social Media Logo - ${capitalize(socialAccount.handle)}`,
            });
          }}
        >
          {icon}
        </a>
      </SocialMediaLink>
    );
  });

  const legalLinks = legalFooter?.map((link) => {
    return (
      <LegalLink key={link?.id}>
        <Link href={link?.url ?? '#'}>
          <a
            onClick={() => {
              gtm.track('click', {
                element_type: 'link',
                location: ANALYTICS_CATEGORY,
                element_label: maybeNotSet(link?.title),
              });
            }}
          >
            {link?.title}
          </a>
        </Link>
      </LegalLink>
    );
  });

  // Add "Your Privacy Choices" link/button for CCPA regulations
  if ((geo?.country ?? '').toUpperCase() === 'US') {
    legalLinks?.push(
      <LegalLink>
        <a
          onClick={() => {
            toggleInfoDisplay();
          }}
        >
          Your Privacy Choices <PrivacyChoicesGlyph width={28} />
        </a>
      </LegalLink>,
    );
  }

  return (
    <Footer as="footer">
      <nav>
        {/* Links */}
        <CategoryWrapper>
          {primaryFooter?.map((category) => (
            <Category key={category?.id}>
              <CategoryEyebrow>{category?.title}</CategoryEyebrow>

              <ul aria-label={category?.title!}>
                {category?.children?.map((link) => (
                  <CategoryListItem bold={link?.isBold} key={link?.id}>
                    <Link href={link?.url ?? '#'}>
                      <a
                        onClick={() => {
                          gtm.track('click', {
                            element_type: 'link',
                            location: ANALYTICS_CATEGORY,
                            element_label: `${maybeNotSet(category?.title)} > ${maybeNotSet(link?.title)}`,
                          });
                        }}
                      >
                        {link?.title}
                      </a>
                    </Link>
                  </CategoryListItem>
                ))}
              </ul>
            </Category>
          ))}
        </CategoryWrapper>

        <SplitSections>
          {/* Social Links */}
          <SocialMediaLinks aria-label="Links to social media accounts">{socialMediaLinks}</SocialMediaLinks>

          {/* Legal Links */}
          <LegalLinks aria-label="Footer links">{legalLinks}</LegalLinks>

          {/* Legal Footer */}
          <LegalFooterCopy>
            &copy; {new Date().getFullYear()} {FRONT_LEGAL_NAME} All rights reserved.
          </LegalFooterCopy>
        </SplitSections>
      </nav>
    </Footer>
  );
};

export default PrimaryFooter;
