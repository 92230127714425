import { MetadataQuery } from '@/graphql';
import { kebabCase } from 'lodash';
import Head from 'next/head';

interface CraftMetadataProps {
  metadata: MetadataQuery['metadata'];
}

const CraftMetadata = ({ metadata }: CraftMetadataProps) => {
  let metaTitle;
  let metaTags: any[] = [];
  let metaLinks: any[] = [];
  let metaJsonLd;

  if (metadata?.metaTitleContainer) {
    const { title } = JSON.parse(metadata.metaTitleContainer);

    metaTitle = <title>{title.title}</title>;
  }

  if (metadata?.metaTagContainer) {
    const tags: { [key: string]: MetaTag | MetaTag[] } = JSON.parse(metadata.metaTagContainer);

    Object.keys(tags).forEach((tag) => {
      const thisTag = tags[tag];

      if (Array.isArray(thisTag)) {
        thisTag.forEach(({ name, property, content }) => {
          metaTags.push({
            name: name ?? property ?? '',
            property: property ?? name ?? '',
            content: content ?? '',
          });
        });
      } else {
        metaTags.push({
          name: thisTag.name ?? thisTag.property ?? '',
          property: thisTag.property ?? thisTag.name ?? '',
          content: thisTag.content ?? '',
        } as MetaTag);
      }
    });
  }

  if (metaTags.length > 0) {
    metaTags = metaTags
      .filter((tag) => Object.values(tag).every((value) => value !== ''))
      .map((tag) => <meta {...tag} key={`meta-${kebabCase(tag.name)}`} />);
  }

  if (metadata?.metaLinkContainer) {
    const links = JSON.parse(metadata.metaLinkContainer);

    Object.keys(links).forEach((link) => {
      if (!Array.isArray(links[link])) {
        metaLinks.push(links[link]);
      }
    });

    metaLinks = metaLinks.map((link) => <link {...link} key={`link-${kebabCase(link.rel)}`} />);
  }

  if (metadata?.metaJsonLdContainer) {
    metaJsonLd = (
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: metadata.metaJsonLdContainer }} />
    );
  }

  return (
    <Head>
      {metaTitle}
      {metaTags}
      {metaLinks}
      {metaJsonLd}
    </Head>
  );
};

export { CraftMetadata }; /* eslint-disable-line import/prefer-default-export */

type MetaTag = {
  name: string;
  property?: string;
  content?: string;
};
