import { GA4EventParameters, GA4Events, GTM_EVENTS, GTM_VARIABLES } from '@/constants/gtm';
import { mapKeys, mapValues, omitBy, snakeCase, toPairs } from 'lodash';
import Script from 'next/script';
import { v4 as uuidv4 } from 'uuid';
import { createLogger } from './debug';

const GTM_ID = 'GTM-KXF5F7W';
const GA4_ID = 'G-1YQGD4058C';
const DATALAYER_NAME = 'dataLayer';
const logger = createLogger('google tag manager');

let cachedClientId: string | null = null;
let lastEvent: { [key: string]: any } = {};

/* List of variables not to be cleared between events */
const PERMANENT_VARIABLES: string[] = [
  GTM_VARIABLES.CLEARBIT_COMPANY_EMPLOYEE_RANGE,
  GTM_VARIABLES.CLEARBIT_COMPANY_INDUSTRY,
  GTM_VARIABLES.CLEARBIT_COMPANY_NAME,
  GTM_VARIABLES.CLEARBIT_COMPANY_REVENUE_RANGE,
  GTM_VARIABLES.CLEARBIT_COMPANY_SUBINDUSTRY,
  GTM_VARIABLES.GOOGLE_CLIENT_ID,
  GTM_VARIABLES.OPTIMIZELY_VARIATION_IDS,
  GTM_VARIABLES.USER_EMAIL,
  GTM_VARIABLES.USER_FIRST_NAME,
  GTM_VARIABLES.USER_LAST_NAME,
  GTM_VARIABLES.USER_PHONE,
];

const event = async (eventName: string, props?: { [key: string]: any }) => {
  const resetValues = mapValues(lastEvent, (value, key) => (PERMANENT_VARIABLES.includes(key) ? value : undefined));
  const eventProps = mapKeys(props, (value, key) => snakeCase(key));

  const eventObject = {
    ...resetValues,
    ...eventProps,
    event: snakeCase(eventName),
    uniqueEventId: uuidv4(),
  };

  if (!eventObject[GTM_VARIABLES.GOOGLE_CLIENT_ID]) {
    eventObject[GTM_VARIABLES.GOOGLE_CLIENT_ID] = await getClientId();
  }

  /* Set the last event and remove all undefined properties */
  lastEvent = omitBy({ ...eventObject }, (value, key) => value === undefined || value === null);

  logger
    .withDefaults({
      additional: toPairs(eventObject).map((pair) => pair.join(': ')),
    })
    .log(`Event "${eventObject.event}" triggered`);

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      logger.error(`Event callback for "${eventObject.event}" timed out`);
      reject(eventObject);
    }, 10000);

    try {
      window[DATALAYER_NAME].push({
        ...eventObject,
        eventCallback: (containerId: string) => {
          logger.success(`[${containerId}] Event "${eventObject.event}" logged successfully`);
          resolve(eventObject);
        },
      });
    } catch (e) {
      logger.fatal(`Failed to push event "${eventObject.event}" to datalayer`);
      logger.error(e);
      reject(eventObject);
    } finally {
      clearTimeout(timeout);
    }
  });
};

const pageview = (initial: boolean = false, props?: { [key: string]: any }) => {
  return event(GTM_EVENTS.PAGEVIEW, {
    ...props,
    [GTM_VARIABLES.INITIAL_PAGE_VIEW]: initial,
  });
};

const track = (eventName: GA4Events, parameters?: GA4EventParameters) => {
  try {
    window.gtag('event', eventName, {
      ...parameters,
      event_callback: () => {
        logger.success(`[${GA4_ID}] Event "${eventName}" tracked successfully`);
      },
      send_to: GA4_ID,
    });
  } catch (e) {
    logger.fatal(`Failed to track event "${eventName}"`);
    logger.error(e);
  }
};

const trackDemoRequest = (props?: { [key: string]: any }) => {
  event(GTM_EVENTS.DEMO_REQUEST, props);

  track('requested_demo');
  track('generate_lead');

  track('submit_form', {
    form_name: 'Demo Request',
  });
};

const trackTrialSignup = (props?: { [key: string]: any }) => {
  event(GTM_EVENTS.TRIAL_SIGNUP, props);

  track('trial_signup');
  track('generate_lead');

  track('submit_form', {
    form_name: 'Free Trial',
  });
};

const trackBlogSubscribe = () => {
  event(GTM_EVENTS.BLOG_SUBSCRIBE);

  track('subscribed');

  track('submit_form', {
    form_name: 'Blog Subscribe',
  });
};

const getClientId = (): Promise<string> => {
  return new Promise<string>((resolve) => {
    if (cachedClientId !== null) {
      resolve(cachedClientId);

      if (cachedClientId === '') {
        logger.warn('Cached Google clientID is blank');
      }

      return;
    }

    const reject = (e: Error) => {
      logger.error('Could not get client ID', (e as Error).message);
      // Set the cached clientId
      cachedClientId = '';
      // If we can't get the client ID, just pass over nothing
      resolve('');
    };

    const timeout = setTimeout(() => {
      const e = new Error('Timed out waiting for GA4 client ID');

      reject(e);
    }, 2000);

    try {
      window.gtag('get', GA4_ID, 'client_id', (clientId: string) => {
        clearTimeout(timeout);
        cachedClientId = clientId;
        resolve(clientId);
      });
    } catch (e) {
      clearTimeout(timeout);
      reject(e);
    }
  });
};

const setConsent = (
  options: {
    ad_storage: boolean;
    ad_user_data: boolean;
    ad_personalization: boolean;
    analytics_storage: boolean;
  },
  isDefault: boolean = false,
) => {
  const defaultConsent = mapValues(options, (status, key) => (status ? 'granted' : 'denied'));

  logger.log(`Setting ${isDefault ? 'default' : 'updated'} consent\n`, defaultConsent);

  window.gtag('consent', isDefault ? 'default' : 'update', defaultConsent);
};

const gtm = {
  event,
  getClientId,
  pageview,
  track,
  trackBlogSubscribe,
  trackDemoRequest,
  trackTrialSignup,
  setConsent,
};

export default gtm;

export const GoogleTagManagerInitScript = (
  // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
  <Script
    id="gtm-init"
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: `
          window['${DATALAYER_NAME}'] = window['${DATALAYER_NAME}'] || [];
          function gtag() { window['${DATALAYER_NAME}'].push(arguments); }
          
          window['${DATALAYER_NAME}'].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
        `,
    }}
  />
);

export const GoogleTagManagerScript = (
  <Script
    id="gtm"
    src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`}
    strategy="afterInteractive"
    type="text/javascript"
    onLoad={() => {
      logger.log('Tag manager loaded');
    }}
  />
);

export const GoogleTagManagerNoScript = (
  <noscript>
    <iframe
      title="Google Tag Manager"
      src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
      height={0}
      width={0}
      style={{
        display: 'none',
        visibility: 'hidden',
      }}
    />
  </noscript>
);
