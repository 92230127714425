import { ReactComponent as XGlyph } from '@/assets/images/svg/glyphs/x.svg';
import Styled from '@/components/banners/GlobalAnnouncementBanner.styled';
import Link from '@/components/Link';
import { GlobalContext } from '@/context';
import { getSingleLink, parseUrlFromCraft } from '@/lib/craft-utils';
import gtm from '@/lib/gtm';
import { maybeNotSet } from '@/lib/typescript-helpers';
import { InternalURL } from '@/lib/urls';
import { COLORS } from '@/styles/color';
import type { Element } from 'domhandler/lib/node';
import HTMLToReact, { Parser as HTMLParser } from 'html-to-react';
import md5 from 'md5';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLocalstorageState } from 'rooks';

const htmlToReactParser = new HTMLParser();
const processNodeDefinitions = new HTMLToReact.ProcessNodeDefinitions();

const GlobalAnnouncementBanner: FC = () => {
  const router = useRouter();
  const [isHydrated, setIsHydrated] = useState<boolean>(false);
  const { announcement } = useContext(GlobalContext);
  const [localStorageValue, setLocalStorageValue] = useLocalstorageState<string>(
    'globalannouncementbanner_dismissed_text',
    '',
  );

  const onLandingPage = router.asPath.startsWith('/lp/');
  const isEnabledForLandingPages = announcement?.enabledForLandingPages ?? true;

  const bannerLink = getSingleLink(announcement?.customLink);

  const hash = useMemo(() => {
    if (!announcement || !announcement.text) return null;

    return md5(announcement.text);
  }, [announcement]);

  const text = useMemo(() => {
    if (!announcement || !announcement.text) return null;

    return htmlToReactParser.parseWithInstructions(announcement.text, () => true, [
      {
        shouldProcessNode: (node) => node.tagName === 'a',
        processNode: (node: Element, children) => (
          <Link href={node.attribs.href}>
            <a
              {...node.attribs}
              onClick={() => {
                gtm.track('click', {
                  location: 'Announcement Banner',
                  element_type: 'link',
                  // element_label: '', // TODO
                  announcement_banner_text: maybeNotSet(announcement.text),
                });
              }}
            >
              {children}
            </a>
          </Link>
        ),
      },
      {
        shouldProcessNode: (node) => true,
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ]);
  }, [announcement]);

  const bannerColor = useMemo(() => {
    const fallbackColor = COLORS.GRAY.HIGHLIGHT;
    const selectedColor = announcement?.brandColor?.label?.toUpperCase();

    if (selectedColor) {
      return COLORS[selectedColor].TINT ?? fallbackColor;
    }

    return fallbackColor;
  }, [announcement]);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  const isDismissed = localStorageValue === hash;

  const onDestinationPage = useMemo<boolean>(() => {
    try {
      const destination = new InternalURL(parseUrlFromCraft(bannerLink?.url) ?? '');
      const currentPage = new InternalURL(router.asPath);

      return destination && destination.pathname === currentPage.pathname;
    } catch {
      return false;
    }
  }, [router.asPath, announcement]);

  if (
    !announcement ||
    !announcement.enabled ||
    isDismissed ||
    !isHydrated ||
    onDestinationPage ||
    (onLandingPage && !isEnabledForLandingPages)
  ) {
    return null;
  }

  return (
    <Styled.Banner bannerColor={bannerColor}>
      <Styled.Container>
        <Styled.ContentWrapper>
          {text}

          {bannerLink ? (
            <>
              <span>&nbsp;&nbsp;</span>
              <Link href={bannerLink.url}>
                <a
                  onClick={() => {
                    gtm.track('click', {
                      location: 'Announcement Banner',
                      element_type: 'link',
                      element_label: maybeNotSet(bannerLink.text),
                      announcement_banner_text: maybeNotSet(announcement.text),
                    });
                  }}
                >
                  {bannerLink.text}
                </a>
              </Link>
            </>
          ) : null}
        </Styled.ContentWrapper>

        <Styled.DismissButton
          type="button"
          onClick={() => {
            gtm.track('click', {
              location: 'Announcement Banner',
              element_type: 'button',
              element_label: 'Dismiss button',
              announcement_banner_text: maybeNotSet(announcement.text),
            });

            setLocalStorageValue(hash);
          }}
          title="Do not show this banner again"
        >
          <XGlyph color={COLORS.BLACK} width={12} />
        </Styled.DismissButton>
      </Styled.Container>
    </Styled.Banner>
  );
};

export default GlobalAnnouncementBanner;
