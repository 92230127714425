import PrimaryInnerGlowButton from '@/components/buttons/primary';
import Checkbox from '@/components/forms/Checkbox';
import InputField from '@/components/forms/InputField';
import { SignupModalStep } from '@/components/pages/signup/Modal';
import { EMAIL_ERRORS } from '@/constants/email';
import { HubspotFieldLabels, HubspotFieldNames, HubspotFieldValidators, HubspotFormIDs } from '@/constants/hubspot';
import { PRIVACY_NOTICE, SAAS_SERVICES_AGREEMENT } from '@/constants/routes';
import { FRONT_LEGAL_NAME } from '@/constants/strings';
import { TrialSignupPayload } from '@/constants/trial';
import { OneTrustContext } from '@/context/onetrust';
import frontBackend from '@/lib/api/front-backend';
import attribution from '@/lib/attribution';
import { useCustomFormik, useFormikHubspotSubmit } from '@/lib/formik';
import useEmailAvailability, { EmailAvailability } from '@/lib/hooks/useEmailAvailability';
import { isWorkEmail, validatePassword } from '@/lib/validation';
import React, { FC, useContext, useEffect, useState } from 'react';
import Styled from './components';

export type InitialFormValues = Pick<TrialSignupPayload, HubspotFieldNames.Email | 'password'> & {
  [HubspotFieldNames.MarketingEmailOptOut]: boolean;
};

interface InitialFormProps extends SignupModalStep {
  onFormSuccess: (values: InitialFormValues) => void;
}

const InitialForm: FC<InitialFormProps> = ({ heading, subheading, onFormSuccess }) => {
  const { isGDPR } = useContext(OneTrustContext);
  const [emailStatus, setEmail] = useEmailAvailability();
  const [error, setError] = useState('');

  const formik = useCustomFormik<InitialFormValues>({
    initialValues: {
      [HubspotFieldNames.Email]: '',
      password: '',
      [HubspotFieldNames.MarketingEmailOptOut]: false,
    },
    onSubmit: useFormikHubspotSubmit({
      formId: HubspotFormIDs.GetStartedModalPartial,
      name: 'Trial Modal',
      content: 'Trial Modal',
      extraFields: {
        [HubspotFieldNames.Affiliate]: attribution.getAffiliateFromCookie(),
      },
      omitFields: ['password'],
      onSuccess: (values, actions) => {
        setError('');

        frontBackend
          .validateEmail({
            email: values[HubspotFieldNames.Email],
            password: values.password,
            affiliate: values[HubspotFieldNames.Affiliate],
            origin: window?.location?.href ?? '',
          })
          .then(() => {
            onFormSuccess(values);
          })
          .catch((e) => {
            setError('Something went wrong');
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      },
    }),
    validate: (values) => {
      const errors = {};

      errors[HubspotFieldNames.Email] = HubspotFieldValidators[HubspotFieldNames.Email](
        values[HubspotFieldNames.Email],
        {
          required: true,
          allowPersonalEmail: true,
          allowSharedEmail: true,
        },
      );

      switch (emailStatus) {
        case EmailAvailability.Available:
          break;
        case EmailAvailability.Unavailable:
          errors[HubspotFieldNames.Email] = errors[HubspotFieldNames.Email] ?? EMAIL_ERRORS.EXISTS;
          break;
        case EmailAvailability.Error:
          errors[HubspotFieldNames.Email] = errors[HubspotFieldNames.Email] ?? EMAIL_ERRORS.ERROR;
          break;
        default:
          // Prevent submitting without showing an error if there isn't one set already
          errors[HubspotFieldNames.Email] = errors[HubspotFieldNames.Email] ?? '';
      }

      errors['password'] = ((password) => {
        if (!password) {
          return 'Password is required';
        }

        if (!validatePassword(password)) {
          return '8 characters minimum, at least 1 uppercase letter and 1 number';
        }
      })(values['password']);

      if (Object.values(errors).every((val) => val === undefined)) return;

      return errors;
    },
    validateOnBlur: false,
  });

  useEffect(() => {
    setEmail(formik.values[HubspotFieldNames.Email]);
  }, [formik.values[HubspotFieldNames.Email]]);

  useEffect(() => {
    if (emailStatus !== EmailAvailability.Untouched) {
      formik.setFieldTouched(HubspotFieldNames.Email, true, true);
    }
  }, [emailStatus]);

  const checkingEmail = emailStatus === EmailAvailability.Checking;
  const isNotWorkEmail = !isWorkEmail(formik.values[HubspotFieldNames.Email]);

  return (
    <div>
      <h2>{heading}</h2>
      {subheading ? <p>{subheading}</p> : null}

      <form onSubmit={formik.handleSubmit} noValidate>
        <InputField
          type="email"
          name={HubspotFieldNames.Email}
          label={HubspotFieldLabels.Email}
          placeholder="you@company.com"
          value={formik.values[HubspotFieldNames.Email]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik.touched[HubspotFieldNames.Email] && !!formik.errors[HubspotFieldNames.Email]}
          errorMessage={formik.errors[HubspotFieldNames.Email] as string}
          warningMessage={isNotWorkEmail ? "It's best to sign up with your work email" : undefined}
          loading={checkingEmail}
          translucent
        />

        <InputField
          type="password"
          name="password"
          label="Choose a password"
          placeholder="Password"
          value={formik.values['password']}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik.touched['password'] && !!formik.errors['password']}
          errorMessage={formik.errors['password']}
          helpMessage="8 characters minimum, and at least 1 uppercase letter and 1 number"
        />

        <input type="submit" style={{ display: 'none' }} />

        <Styled.FormButtonWrapper>
          <PrimaryInnerGlowButton
            type="button"
            disabled={formik.isValidating || formik.isSubmitting || checkingEmail}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {formik.isSubmitting ? 'Submitting...' : 'Next'}
          </PrimaryInnerGlowButton>
        </Styled.FormButtonWrapper>

        <Styled.LegalNotice>
          <p>
            By signing up, you agree to {FRONT_LEGAL_NAME}&rsquo;s{' '}
            <a href={SAAS_SERVICES_AGREEMENT} target="_blank">
              SaaS Services Agreement
            </a>{' '}
            and{' '}
            <a href={PRIVACY_NOTICE} target="_blank">
              Privacy Notice
            </a>
            .
          </p>

          {isGDPR && (
            <Styled.CheckboxWrapper>
              <Checkbox
                id={HubspotFieldNames.MarketingEmailOptOut}
                checked={formik.values[HubspotFieldNames.MarketingEmailOptOut]}
                onToggle={(checked) => {
                  formik.setFieldValue(HubspotFieldNames.MarketingEmailOptOut, checked);
                }}
              />
              <label htmlFor={HubspotFieldNames.MarketingEmailOptOut}>
                I <strong>do not</strong> want to receive marketing communications from Front.
              </label>
            </Styled.CheckboxWrapper>
          )}
        </Styled.LegalNotice>
      </form>
    </div>
  );
};

export default InitialForm;
