import App from '@/app';
import { OneTrustHideBannerStyles } from '@/context/onetrust';
import ErrorPage500 from '@/layouts/pages/Error500';
import logging from '@/lib/logging';
import { UniversalPageProps } from '@/lib/next';
import GlobalStyles from '@/styles/GlobalStyles';
import '@/styles/reset.css';
import type { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import { AppProps } from 'next/app';
import React from 'react';

const ErrorBoundary: BugsnagErrorBoundary =
  logging.init().getPlugin('react')?.createErrorBoundary(React) ?? (({ children }) => children);

const NextApp = (props: AppProps<UniversalPageProps>) => (
  // @ts-expect-error TODO figure out how to make Typescript happy
  <ErrorBoundary FallbackComponent={ErrorPage500}>
    <GlobalStyles />
    <OneTrustHideBannerStyles />
    <App {...props} />
  </ErrorBoundary>
);

export default NextApp;
